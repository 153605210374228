import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "../../hooks/useDebounce";
import { searchClients } from "../../apis/clients.api";

export default function ReviewPageVendorSearch({ onChange }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [clientListLoading, setClientListLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(inputValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      getClientList(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const getClientList = async (searchTerm: string) => {
    setClientListLoading(true);
    const response: any = await searchClients({
      selectedPage: 1,
      selectedPageSize: 10,
      searchValue: searchTerm,
    });

    if (response?.success) {
      setOptions(response.data);
    }

    setClientListLoading(false);
  };

  return (
    <Autocomplete
      freeSolo
      size="small"
      options={options}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      loading={clientListLoading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Enter vendor name"
          sx={{ fontSize: "14px" }}
          InputProps={{
            ...params.InputProps,
            sx: {
              height: "41px", // Increase the height
              padding: "0 14px", // Adjust padding as needed
            },
            endAdornment: (
              <>
                {clientListLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
