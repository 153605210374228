import { ROOT_PATH } from "../constants";
import { ambassadorRoutePaths } from "./ambassador";
import { authRoutePaths } from "./auth";
import { clientRoutePaths } from "./client";
import { invitationRoutePaths } from "./invitations";
import { marketplaceRoutePaths } from "./marketplace";

const ROUTE_PATHS = {
  HOME: "/",
  ADMIN_HOME: `${ROOT_PATH}`,
  DASHBOARD: `${ROOT_PATH}/dashboard`,
  INVITE_CLIENT_AMBASSADOR: `${ROOT_PATH}/invite-client-ambassador`,
  CLIENT_AMBASSADOR_SLOT_SELECTION: "/client-ambassador-slot-selection",
  AMBASSADOR_CLIENT_SLOT_SELECTION: "/ambassador-slot-selection",
  REVIEWS: "/reviews",
  ...authRoutePaths,
  ...clientRoutePaths,
  ...ambassadorRoutePaths,
  ...invitationRoutePaths,
  ...marketplaceRoutePaths,
  NOT_FOUND: "/404",
  UNAUTHORIZED: "/unauthorized",
  EXTERNAL_WEBSITE_LINK: "https://www.execweb.com",
  SIMPLE_LIST: "/ambassador-simple-list",
  CONNECT_REQUESTS: `${ROOT_PATH}/connect-requests`,
  OPPORTUNITY: `${ROOT_PATH}/connect-opportunity`,
};

export { ROUTE_PATHS };
