import { postApi } from "../services/axios";
import APIs from ".";

export async function fetchReviews(payload = {}, params = undefined) {
  if (!params) {
    const res = await postApi(APIs.TYPEFORM.GET_ALL_REVIEWS, payload, {
      validateUser: true,
    });

    return res;
  }

  const res = await postApi(APIs.TYPEFORM.GET_REVIEWS(params), payload, {
    validateUser: true,
  });

  return res;
}
